// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// ng serve --prod utilisera la constante du fichier environment.prod.ts,
// ng serve utilise la constante du fichier environment.ts
// The list of which env maps to which file can be found in `.angular-cli.json`.
// pour assouplir le paramétrage, utiliser le service env. Notamment pour baseUrl
// Exemple : // https://www.jvandemo.com/how-to-use-environment-variables-to-configure-your-angular-application-without-a-rebuild/
export const environment = {
  production: true,
  // npm run updateBuild -- 1.0.2
  version: '2.0.37',
  buildTimestamp: '2024-11-22 17:27:52',
  // rigthClickEvent: 'contextmenu', // prod
  rigthClickEvent: 'contextmenuDebug',
  baseUrl: 'https://works.nautikonws.org/backend/nautikoWS',
  wsBaseUrl: 'ws://works.nautikonws.org/backend/nautikoWS',
  keycloakProp: {
    // url: 'https://auth.nautikonws.org/auth/realms',
    url: 'https://appointments.nautikonws.org/auth/realms',
    realm: 'nautikoWS-realm',
    clientId: 'nautikoWS-api',
    clientSecret: '27028874-0886-4fc3-88b2-b68c1043589d',
    entityAuth: 'Az789-!!HSJ654wosr*$kk__KKK',
  },
  appTitle: 'Nautiko Work Shop',
  workshop: true,
  appSigle: 'NWS',
  // TODO : il va falloir changer les noms de ces propriétés pour en masquer le role
  trustAccount: 'Majalog',
  trustSecret: '$2a$10$hPXrownofppUmtn7hAHEV.F2ToGpnNPkpuffGwjyQ9Ofx/RhBzEie',
  connectStream: true,
  langDefault: 'en',
  fmtDateTimeBdd: 'YYYY-MM-DD HH:mm:ss',
  fmtDateBdd: 'YYYY-MM-DD',
  fmtHourBdd: 'HH:mm',
  fmtDateTimeTZ: 'YYYY-MM-DDTHH:mm:ss.SSS+0000',
  sexes: 'M,F,T',
  enginesDb: 'PostgreSQL,MySQL,WebDav',
  statutsReport: 'DEV,SUSPEND,WAIT-VALID',
  emailPattern : '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  conditions : [
    { 'id': 1, 'text': 'empty', 'applic': 'string'},
    { 'id': 2, 'text': 'contain', 'applic': 'string'},
    { 'id': 3, 'text': 'start', 'applic': 'string'},
    { 'id': 4, 'text': 'end',  'applic': 'string'},
    { 'id': 5, 'text': 'less than',  'applic': 'number'},
    { 'id': 6, 'text': 'greater than',  'applic': 'number'},
    { 'id': 7, 'text': 'equal',  'applic': 'number'},
  ],
  workIcon: {path: 'assets/img/', name: 'Work', type: 'svg'},
  applicationsList : [
    { 'value': 'reporting', 'display': 'Reporting'},
    { 'value': 'nautiko', 'display': 'NautikoNWS'},
  ],
  emailAttr : {
    emailTypeList: ['prof', 'perso'],
    emailRegex: '(^[a-z]+[a-z0-9_\.]+)(@)([a-z]+)([\.][a-z]{2,5}$)',
    emailDisplay: '$1$2$3$4',
    emailLengthMax: '60',
  },
  indusArabicsNumbers: {
    comment: 'Hindus-arabics : https://omniglot.com/language/numbers/arabic.htm ',
    units: [
      {latin: '0', hindus: '٠'},
      {latin: '1', hindus: '١'},
      {latin: '2', hindus: '٢'},
      {latin: '3', hindus: '٣'},
      {latin: '4', hindus: '٤'},
      {latin: '5', hindus: '٥'},
      {latin: '6', hindus: '٦'},
      {latin: '7', hindus: '٧'},
      {latin: '8', hindus: '٨'},
      {latin: '9', hindus: '٩'}
    ]
  },
  smtpPorts : ['25', '465', '587', '2525'],
  smtpConnects : ['NONE', 'SSL/TLS', 'STARTTLS'],
  customerValidEmailTest: {
    email: 'client.appointment@nautikows.net',
    entity: 'TST_1558'
  }
};
