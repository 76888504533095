import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RemoteFilesService } from '../../../../../common/services/remote-files.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';


import { ReadFile, CommonReport, AlertType, LevelType, FileToDisplay } from '../../../../../../models/common.model';
import { WksCentralService } from '../../../../services/wks-central.service';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { LabelValue} from '../../../../../../models/data.model';
import { environment } from '../../../../../../../environments/environment';
import { WksWorksModel, WksWorksDocs, PhotoData, PhotosEquip, PhotosSequence, PhotosSequences, PhotosTask, WorkEquipments  } from '../../../../models/wks-works.model';
import { IhmOptions, WksTasksModel } from '../../../../models/wks-param.model';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'mdi-works-photos',
  templateUrl: './works-photos.component.html',
  styleUrls: ['./works-photos.component.css']
})
export class WorksPhotosComponent implements OnInit, OnChanges {

  @Input() ihmOptions: IhmOptions;
  @Input() photoData: PhotoData;
  @Input() wksWorks: WksWorksModel;
  @Input() workTasksContent: WksTasksModel[];
  @Input() workEquipments: WorkEquipments;

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  
  private readonly onDestroy = new Subject<void>();

  noPhotos: boolean;
  loadingPhotosInProgress: boolean;
  displayPhotos: boolean;
  sidenavCollapsed: boolean;
  hasBackdrop: boolean;
  // settingIntFmts
  timeFormat: string;
  dateFormat: string;
  fieldFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
    language: string;

  };
  dateFormatMoment: string;

  // files photos
  filesToDisplay: FileToDisplay[];
  imgFiles: any[];
  photosEquip: PhotosEquip[];
  imagesViewerList: any[] = [];
  imagesLegendList: any[] = [];
  imagesToSaveList: FileToDisplay[] = [];
  imagesViewerDisplay: boolean;
  imageIndex = 0;
  dateTimeSequenceSelected: string = undefined;
  
  constructor(private userService: UserService,
            private translateService: TranslateService,
            private remoteFilesService: RemoteFilesService,
            private ressourcesService: RessourcesService,
            private wksCentralService: WksCentralService,
            private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.initData();
  }
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          }
          case 'photoData': {
            this.photoData = changes['photoData'].currentValue;
            break;
          }
          case 'wksWorks': {
            this.wksWorks = changes['wksWorks'].currentValue;
            break;
          }
          case 'workTasksContent': {
            this.workTasksContent = changes['workTasksContent'].currentValue;
            break;
          }
          case 'workEquipments': {
            this.workEquipments = changes['workEquipments'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop

    this.initData();
  }
  initData(): void {
    this.initBooleans();
    this.settingIntFmts();
    this.initVariables();
    this.loadPhotos();
  }
  initBooleans(): void {
    this.noPhotos = true;
    this.loadingPhotosInProgress = false;
    this.displayPhotos = false;
    this.sidenavCollapsed = true;
    this.hasBackdrop = true;
    this.imagesViewerDisplay = false;
  }
  initVariables(): void {
    this.photosEquip = [];
  }
  settingIntFmts(): boolean {
    const wksEntity = this.wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.fieldFormat = {
      locale: this.ressourcesService.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this.ressourcesService.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this.ressourcesService.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency: this.ressourcesService.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency: this.ressourcesService.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText: this.ressourcesService.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers: this.ressourcesService.getFormat(internationnalFormat, 'justifs', 'numbers'),
      language:  this.ressourcesService.getFormat(internationnalFormat, 'numbers', 'language'),
    };
    this.timeFormat = this.translateService.getLocalFmt('timeFormat');
    this.dateFormat  = this.translateService.getLocalFmt('dateFormat');

    this.dateFormatMoment = this.dateFormat;
    this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
    this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');

    return true;
  }

  toggleSidenav(_arg: string) {

    if (_arg === 'backdrop') {
      return;
    }  
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  loadPhotos(): void {
    this.loadingPhotosInProgress = true;
    this.noPhotos = false;
    this.displayPhotos = true;
    
    this.filesToDisplay = [];
    this.photosEquip = [];

    if (this.wksWorks.workDocs)  {
      for (const docCur of this.wksWorks.workDocs as WksWorksDocs []) {
        if (docCur.workdocType !== 'photo' ) {
          continue;
        }
        const fileToDisplay = {
          fileName: docCur.workdocFilename,
          fileLegend: docCur.workdocLegend,
          filePath: docCur.workdocPath + '/' + docCur.workdocFilename,
          dataImgLoaded: false,
          imageToShow: undefined,
          fileNameFormatted: this.formatFileName(docCur.workdocFilename)
        };
        this.filesToDisplay.push(fileToDisplay);
        this.photosSequenceList(fileToDisplay, docCur);
      }
      if (this.filesToDisplay.length === 0) {
        this.displayPhotos = false;
        this.noPhotos = true;
        this.loadingPhotosInProgress = false;
      } else {
        this.sortPhotosSequenceList();
        this.generateImgList();
      }
    } else {
      this.displayPhotos = false;
      this.noPhotos = true;
      this.loadingPhotosInProgress = false;
    }
  }
  formatFileName(fileName: string): string {
    if (fileName === null) {
      return fileName;
    }
    const fileNameTmp = fileName.split('.');
    const fileNameSplit = fileNameTmp[0].split('_');
    if (fileNameSplit.length === 1) {
      return fileName;
    }
//    const dateStringCur = fileNameSplit[0].split('-');
    // const TimeStringCur = fileNameSplit[1].split('-');

    let dateTimeCur = fileNameSplit[0] + ' ' + fileNameSplit[1];
    if (fileNameSplit[1].length === 4)  {
      dateTimeCur = dateTimeCur + '00';
    } 

    let dateTimeDef = dateTimeCur.substring(0, 4) + '-' + dateTimeCur.substring(4, 6) + '-' + dateTimeCur.substring(6, 8) ;
    dateTimeDef = dateTimeDef + ' ' + dateTimeCur.substring(9, 11) + ':' + dateTimeCur.substring(11, 13) + ':' + dateTimeCur.substring(13, 15);

    const formattedLabel = CommonMethods.dateToString(dateTimeDef, 'json2', this.timeFormat); 
    if (formattedLabel === null || formattedLabel === undefined ) {
      return fileName;
    }
    return formattedLabel;
  }
  sortPhotosSequenceList(): void {

    for (const equipItem of this.photosEquip) {
      equipItem.isCollapsed = true;
      for (const taskItem of equipItem.photosTask) { 
        taskItem.isCollapsed = true;
        taskItem.photosSequences.sort((obj1: PhotosSequences, obj2: PhotosSequences) => {
          return obj1.order > obj2.order ? 1 : -1;
        });
        for (const sequenceItem of taskItem.photosSequences) { 
          sequenceItem.isCollapsed = true;
          sequenceItem.photosSequence.sort((obj1: PhotosSequence, obj2: PhotosSequence) => {
            return obj1.sequenceDateTime > obj2.sequenceDateTime ? 1 : -1;
          });
          for ( const photosSequenceItem of sequenceItem.photosSequence) { 
            photosSequenceItem.isCollapsed = true;
          }
        }
      }
    }
  }
  photosSequenceList(fileToDisplayCur: FileToDisplay, docCur: WksWorksDocs): void {
    // TST_1558/works/fe5561f6eb65469b949786a853ac6512/photo/2024-11-06_10:14
    const pathItems = docCur.workdocPath.split('/');
    
    const dateTimeSequence = pathItems[pathItems.length - 1];
    const jsonData = JSON.parse(docCur.workdocJson);
    let photoEquipExists = false;

    const photoDataTmp = jsonData.photoData as PhotoData; 
    for (const equipItem of this.photosEquip) { 
      if (equipItem.equipId === photoDataTmp.equipId) { 
        for (const taskItem of equipItem.photosTask) { 
          if (taskItem.taskId === photoDataTmp.taskId) { 
            for (const sequenceItem of taskItem.photosSequences) { 
              if (sequenceItem.sequenceAtt === photoDataTmp.sequenceAtt) { 
                for (const sequenceTimeItem of sequenceItem.photosSequence ) { 
                  if (sequenceTimeItem.sequenceDateTime === dateTimeSequence) { 
                    photoEquipExists = true;
                    this.getPhotosSequence(fileToDisplayCur, dateTimeSequence, sequenceTimeItem);
                  }
                } // end loop photosSequence
                if (!photoEquipExists) { 
                  sequenceItem.photosSequence.push(this.getPhotosSequence(fileToDisplayCur, dateTimeSequence, undefined));
                  photoEquipExists = true;
                }  
              } // end test sequenceAtt 
            } // end loop photoSequences
            if (!photoEquipExists) { 
              const photosSequences = this.getItemPhotosSequences(photoDataTmp);
              photosSequences.photosSequence.push(this.getPhotosSequence(fileToDisplayCur, dateTimeSequence, undefined));
              if (taskItem.photosSequences === undefined || taskItem.photosSequences.length === 0) { 
                taskItem.photosSequences = [];
              } 
              taskItem.photosSequences.push(photosSequences);
              photoEquipExists = true;
            }
          } // end test taskId
        } // end loop task
        if (!photoEquipExists) { 
          const photosSequences = this.getItemPhotosSequences(photoDataTmp);
          photosSequences.photosSequence.push(this.getPhotosSequence(fileToDisplayCur, dateTimeSequence, undefined));
          const photosTaskTmp = this.getItemPhotosTask(photoDataTmp);
          photosTaskTmp.photosSequences.push(photosSequences);
          if (equipItem.photosTask === undefined  || equipItem.photosTask.length === 0)  { 
            equipItem.photosTask = [];
          }
          equipItem.photosTask.push(photosTaskTmp);
          photoEquipExists = true;
        }
      } // end test equipId 

    } // end loop equipId 
    if (!photoEquipExists) { 
      const photosSequences = this.getItemPhotosSequences(photoDataTmp);
      photosSequences.photosSequence.push(this.getPhotosSequence(fileToDisplayCur, dateTimeSequence, undefined));
      const photosTaskTmp = this.getItemPhotosTask(photoDataTmp);
      photosTaskTmp.photosSequences.push(photosSequences);
      const photoEquipTmp = this.getItemEquip(photoDataTmp);
      photoEquipTmp.photosTask.push(photosTaskTmp);

      if (this.photosEquip === undefined  || this.photosEquip.length === 0)  { 
        this.photosEquip = [];
      }
      this.photosEquip.push(photoEquipTmp);
    }
  }

  getPhotosSequence(fileToDisplayCur: FileToDisplay, dateTimeSequence: string, photosSequenceCur: PhotosSequence): PhotosSequence { 

    if (photosSequenceCur === undefined) { 
      const filesToDisplayTmp: FileToDisplay[] = [];
      filesToDisplayTmp.push(fileToDisplayCur);
      const sequenceTimeNew: PhotosSequence = { 
        sequenceDateTime: dateTimeSequence,
        sequenceDateTimeLabel: this.translateService.instant('sequenceLabel') + ' > ' + dateTimeSequence,
        filesToDisplay: filesToDisplayTmp
      };
      return sequenceTimeNew;
    } else  { 
      photosSequenceCur.filesToDisplay.push(fileToDisplayCur);
      return photosSequenceCur;
    }

  }
  getItemPhotosSequences(photoData: PhotoData): PhotosSequences {

    const orderTmp = photoData.sequenceAtt === 'BEFORE' ? 1 : photoData.sequenceAtt === 'DURING' ? 2 : photoData.sequenceAtt === 'AFTER' ? 3 : 0 ;
    const photosSequencesTmp: PhotosSequences = {
      order : orderTmp,
      sequenceAtt: photoData.sequenceAtt,
      photosSequence: []
    };
    return photosSequencesTmp;
  } 
  getItemPhotosTask(photoData: PhotoData): PhotosTask {

    let taskLabelTmp: string;
    for (const taskCur of this.workTasksContent) {
      if (taskCur.id === photoData.taskId) {
        taskLabelTmp = taskCur.taskrefLabel;
        break;
      }
    }
    const photosTaskTmp: PhotosTask =  {
      taskId: photoData.taskId,
      taskLabel: taskLabelTmp,
      photosSequences: []
    };
    return photosTaskTmp;
  }
  getItemEquip(photoData: PhotoData): PhotosEquip {

    let equipLabelTmp: string;
    
    if (this.workEquipments.mainEquipment.id === photoData.equipId ) {

      equipLabelTmp = this.translateService.instant(this.workEquipments.mainEquipment.equipType) + ' ' +
                    (this.workEquipments.mainEquipment.equipDesign ? this.workEquipments.mainEquipment.equipDesign : this.workEquipments.mainEquipment.equipName);
    } else {
      for (const equipmentCur of this.workEquipments.subEquipments) {
        if (equipmentCur.id === photoData.equipId ) {
          equipLabelTmp = this.translateService.instant(equipmentCur.equipType) + ' ' +
                          (equipmentCur.equipDesign ? equipmentCur.equipDesign : equipmentCur.equipName);
          break;
        }
      }
    }

    const photosEquipTmp: PhotosEquip = {
      equipId: photoData.equipId,
      equipLabel: equipLabelTmp,
      photosTask: []
    };
    return photosEquipTmp;
  }
  generateImgList(): void {

    let nbFilesLoaded = 0;
    this.imgFiles = [];
    for (const imgCur of this.filesToDisplay ) {
      this.imgFiles.push(imgCur);
      this.wksCentralService.downloadImgFile(imgCur.filePath, imgCur.fileName)
      .then ((data: Blob) => {
        imgCur.fileData = data;
        this.createImageFromBlob(data, imgCur);
        // imgCur.imageToShow = URL.createObjectURL(data);
        nbFilesLoaded++;
        if (nbFilesLoaded === this.filesToDisplay.length)  {
          this.loadingPhotosInProgress = false;
          this.displayPhotos = true;
        }
      },
      (error: any) => { // error read label
        imgCur.dataImgLoaded = false;
        imgCur.imageToShow = undefined;
        this.loadingPhotosInProgress = false;
        this.displayPhotos = false;
        this.noPhotos = true;
        this.imgFiles = [];
      });   
    }
  }
   // https://stackblitz.com/edit/angular-1yr75s?file=src%2Fapp%2Fapp.component.ts
   createImageFromBlob(image: Blob, _imgCur: FileToDisplay): any {
    const reader = new FileReader();
    
    reader.addEventListener('load', () => {
      
      _imgCur.imageToShow = reader.result;
      const fileSafe = this.sanitizer.bypassSecurityTrustUrl(_imgCur.imageToShow);
      _imgCur.imageToShow = fileSafe;

      _imgCur.dataImgLoaded = true;
      }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
// ngx-image-viewer
// https://www.npmjs.com/package/ngx-image-viewer
  initViewerList(filesToDisplay: FileToDisplay[], dateTimeSequence: string): void {
    this.imagesViewerDisplay = false;
    this.dateTimeSequenceSelected = dateTimeSequence;
    this.imagesViewerList = [];
    this.imagesLegendList = [];
    this.imagesToSaveList = [];
    for (const fileToDisplay of filesToDisplay) {
      this.imagesViewerList.push(fileToDisplay.imageToShow);
      this.imagesLegendList.push(fileToDisplay.fileLegend);
      this.imagesToSaveList.push(fileToDisplay);
    }
    this.imageIndex = 1;
    this.imagesViewerDisplay = true;
  } 
  handleEventPhotosView(event: any): void {
    // console.log(`${event.name} has been click on img ${event.imageIndex + 1}`);

    switch (event.name) {
      case 'print':
        console.log('run print logic');
        break;
      case 'download':
        const fileToSave = this.imagesToSaveList[event.imageIndex];
        this.savePhoto(fileToSave);
        break;
    }
  }
  indexChangeEventPhotosView(event: number): void {
    // console.log(`${event} has been click on img `);
    this.imageIndex = event + 1;
  }
  savePhoto(filesToSave: FileToDisplay) {

    const fileExtension = filesToSave.fileName.split('.').pop();
    const mimeApp = this.wksCentralService.getContentMime(fileExtension);
    this.wksCentralService.saveToFileSystem(filesToSave.fileData, filesToSave.fileName, mimeApp);
    
  }
}
