import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of, Subject } from 'rxjs';

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';


import { catchError, tap, map, takeUntil} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';
import { RessourcesService } from '../../../job/common/services/ressources.service';
import { TranslateService } from '../../../services/translate.service';
import { MaxDefaults, RoleModel } from '../../../models/user.model';
import { ColDef } from '../../../models/tables.model';
import { environment } from '../../../../environments/environment';
import { CommonMethods } from '../../common/tools/commonMethods';
import { MenuWksModel, WksTmpData, MeasurementList } from '../models/wks-common.model';
import { WksBusinessModel, WksContactsModel, PaiementsTerm, BsnAccountingModel, AccountingModel, AccountingBalance } from '../models/wks-business.model';
import { SmtpModel, WksEntityModel } from '../models/wks-entity.model';
import { WksEquipmentModel, EquipEvent, DocEvent, AnchorageEvent, WksAnchoragesModel,
          MotorsConfigModel, PropulsionsModel, ThrusterConfigModel, WksDocsModel, TrailersOptions } from '../models/wks-equipment.model';
import { ParamWorksRatesModel, ParamWorksRatesEvent, ParamActionsrefModel, 
          WksTasksModel, WorkStatutModel, ParamPartsSuppliesModel, UpdateRatesModel, 
          EventPartsSupplies, IhmOptions, ParamCustoms  } from '../models/wks-param.model';
import { WksWorksModel, WksWorksDocs, WksUuidLinksId, WksEquipmentsRequest, WksEquipmentUsage, PhotoData } from '../models/wks-works.model';
import { BrandWksModel, TaskWksEvent } from '../models/wks-common.model';

// AppointmentModel
import { AppointmentModel, AppointmentTracking } from '../models/wks-appointments.model';
import { AlgoDatas, ToolApi, ToolTest, ToolArgs, ToolsApisType } from '../../../models/common.model';
import { Manufacturers, SpecificationsProcess, StepProcess } from '../models/wks-manufacturers.model';
import { LabelValue } from '../../../models/data.model';
import { ClassDef } from '../../..//models/schedulerJob.model';
// import WKS_PARAMS from '../../../../assets/data/wks_params.json';
// import CONTENTS_MIME from '../../../../assets/data/contents-mime.json';

@Injectable({
  providedIn: 'root'
})
export class WksCentralService {


  public wksMenuItemsEvents = new BehaviorSubject<string>(undefined);
  public wksMenuButtonEvents = new BehaviorSubject<any>(undefined);
  public onChangeStatut: EventEmitter<string> = new EventEmitter<string>();
  public onRegisterValid: EventEmitter<string> = new EventEmitter<string>();
  public taskWksEvent: EventEmitter<TaskWksEvent> = new EventEmitter<TaskWksEvent>();
  public onSearchList: EventEmitter<EquipEvent> = new EventEmitter<EquipEvent>();
  public onSearchDoc: EventEmitter<DocEvent> = new EventEmitter<DocEvent>();
  public onSearchAnchorage: EventEmitter<AnchorageEvent> = new EventEmitter<AnchorageEvent>();
  public onSearchParamWorkRate: EventEmitter<ParamWorksRatesEvent> = new EventEmitter<ParamWorksRatesEvent>();
  public onSearchParts: EventEmitter<EventPartsSupplies> = new EventEmitter<EventPartsSupplies>();

  httpHeaders: HttpHeaders;
  httpParams: HttpParams;
  entityCur: WksEntityModel;
  wksMenuItems: any;
  // wksParamsStr = JSON.stringify(WKS_PARAMS);
  // wksParamsJson = JSON.parse(this.wksParamsStr);
  // wksContentsMime = JSON.parse(JSON.stringify(CONTENTS_MIME));

  wksParamsStr: any;
  wksParamsJson: any;
  // wksContentsMime: any;
  wksContentsMimeMap: Map<string, string> = new Map<string, string>();
  wksAnchorages: LabelValue[]; 
  wksAnchoragesWintering: LabelValue[]; 
  workStatutsEntity: WorkStatutModel[]; 

  private readonly onDestroy = new Subject<void>();
  
  constructor(public  httpClient: HttpClient, 
          private _clipboardService: ClipboardService,
          private _ressourceService: RessourcesService,
          private _translate: TranslateService ) {
   //  const locCountry = wksParamsJson[countryKey] as CountryDef;
   }
   loadContentsMime(): Observable<HttpResponse<any>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlCur = `${environment.baseUrl}/config/contentsMime`;

    return this.httpClient.get<String>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }
  loadLocalDef(countryKey: string): Observable<HttpResponse<any>> {

    const urlService = `${environment.baseUrl}/wks/jobs/getLocalDef/?` + 'countrykey=' + countryKey;
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });

  }
  loadCountryFmt(countryKey: string): Observable<HttpResponse<any>> {

    const urlService = `${environment.baseUrl}/wks/jobs/getCountryFormat/?` + 'countrykey=' + countryKey;
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });

  }
  loadWksParams(): Observable<HttpResponse<any>> {

    // return this.http.get('/getallfiles');
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    const urlCur = `${environment.baseUrl}/config/wksParams`;

    return this.httpClient.get<any>(urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response'
      });
  }

  setContentsMime(_contentsMime: {mimeType: string; keyType: string}[]) {
    
    // const jsonMimeArray: {mimeType: string; keyType: string}[] = JSON.parse(_contentsMime);
    // const jsonMimeArray: {mimeType: string; keyType: string}[] = _contentsMime;
    for (const mimeItem of _contentsMime) {
      // console.log (mimeItem);
      this.wksContentsMimeMap.set(mimeItem.keyType, mimeItem.mimeType);
    }
    // this.wksContentsMime = JSON.parse(JSON.stringify(_contentsMime));

  }
  setWksParams(_wksParams: any, _entityActivity: string) {
    this.wksParamsStr = JSON.stringify(_wksParams);
    this.wksParamsJson = JSON.parse(this.wksParamsStr);
    this.workStatutsEntity = [];
    if (_entityActivity === 'workshop') {
      this.workStatutsEntity = this.wksParamsJson.workStatuts.workshop;
    }
    if (_entityActivity === 'concierge') {
      this.workStatutsEntity = this.wksParamsJson.workStatuts.concierge;
    }
  }
  // https://www.npmjs.com/package/ngx-clipboard
  copyToClipboard(text: string) {
    this._clipboardService.copyFromContent(text);
   }
  setHeaders(_headers: HttpHeaders) {
    this.httpHeaders = _headers;
  }

  getContentMime(_fileExtension: string): any {
    return this.wksContentsMimeMap.get(_fileExtension);
    // return this.wksContentsMime[_fileExtension];
 
  }
  /*================================================================== WKS_PARAMS --*/ 
  public getGoogleAPIKey(): String {
    return this.wksParamsJson.google.apikey;
  }
  public getTasksHeadingsList(): string[] {
    return this.wksParamsJson.tasksheadings;
  }
  public getComponentsList(): string[] {
    return this.wksParamsJson.typologies.components;
  }
  public getComponentsHours(): boolean[] {
    return this.wksParamsJson.typologies.hours;
  }
  public getEquipmentsList(): any[] {
    return this.wksParamsJson.typologies.equipments;
  }
  public getPropulsionsList(): PropulsionsModel[] {
    return this.wksParamsJson.typologies.propulsions;
  }
  public getMotorsconfigList(): MotorsConfigModel[] {
    /*const wksParamsJsonLocal = JSON.parse(this.wksParamsStr);
    return wksParamsJsonLocal.typologies.motorsconfig;*/
    return this.wksParamsJson.typologies.motorsconfig;
  }
  public getThrustersconfigList(): ThrusterConfigModel[] {
    return this.wksParamsJson.typologies.thrustersconfig;
  }
  // PaiementsTerm
  public getPaiementsTerm(): PaiementsTerm {
    return this.wksParamsJson.paiementsTerm;
  }
  public getPaginationFormats(): string[] {
    return this.wksParamsJson.paginationFormat;
  }
  public getTrailersOptions(): TrailersOptions {
    return this.wksParamsJson.trailersoptions;
  }
  public getDocClassification(): string[] {
    return this.wksParamsJson.typologies.docclassification;
  }
  public getBillingUnits(): string[] {
    return this.wksParamsJson.billingUnits;
  }
  public getTaskActions(): string[] {
    return this.wksParamsJson.taskActions;
  }
  public getUnitsQuantity(): MeasurementList[] {
    return this.wksParamsJson.unitsquantity;
  }
  public getCriticality(): string[] {
    return this.wksParamsJson.criticality;
  }
  public getWorkStatuts(): WorkStatutModel[] {
    // return this.wksParamsJson.workStatuts;
    return this.workStatutsEntity;
  }
  public getWksSupportedRoles(): RoleModel[] {
    return this.wksParamsJson.supportedRoles;
  }
  public getWksMaxDefaults(): MaxDefaults {
    return this.wksParamsJson.maxDefaults;
  }
  public getPhonesType(): string[] {
    return this.wksParamsJson.communications.phonesType;
  }
  public getNotifApp(): string[] {
    return this.wksParamsJson.communications.notificationsApp;
  }
  public getWorkStatut(_label: string): WorkStatutModel {
    /*
    for (const workstatutCur of this.wksParamsJson.workStatuts) {
      if (workstatutCur.label === _label) { 
        return workstatutCur;
      }
    }
    */
    for (const workstatutCur of this.workStatutsEntity) {
      if (workstatutCur.label === _label) { 
        return workstatutCur;
      }
    }
    return null;     
  }
  public getIhmOption(optionName: string, workstatutCur: WorkStatutModel): IhmOptions {
    let ihmOptionsCur: IhmOptions; 
    for (const optionCur of workstatutCur.ihmOptions)  {
      if (optionCur.optionName === optionName) {
        ihmOptionsCur = optionCur;
      }
    }
    if (ihmOptionsCur === undefined) {
      ihmOptionsCur = {
        optionName: optionName,
        readOnly: true,
        display: false,
      };
    }
    return ihmOptionsCur;
  }
  public getColumnsHeadersAnchoragesCsv(): ColDef[] {
    return this.wksParamsJson.anchorages.columnsCsv;
  }
  public getColumnsHeadersPartsSuppliesCsv(): ColDef[] {
    return this.wksParamsJson.partsSupplies.columnsCsv;
  }
  public getTypesPartsSupplies(): string[] {
    return this.wksParamsJson.partsSupplies.paramType;
  }
  public getColumnsHeadersPartsReferentialCsv(): ColDef[] {
    return this.wksParamsJson.partsReferential.columnsCsv;
  }
  public getColumnsHeadersImportSupplyCsv(): ColDef[] {
    return this.wksParamsJson.importSupply.columnsCsv;
  }
  public getColumnsHeadersImportSupplyFinalCsv(): ColDef[] {
    return this.wksParamsJson.importSupplyFinal.columnsCsv;
  }
  public getColumnsHeadersLocalSupplyCsv(): ColDef[] {
    return this.wksParamsJson.localSupply.columnsCsv;
  }
  public getColumnsHeadersLocalSupplyFinalCsv(): ColDef[] {
    return this.wksParamsJson.localSupplyFinal.columnsCsv;
  }
  public getAlgoCalculationData(): AlgoDatas {
    return this.wksParamsJson.algoCalcul;
  }
  public getApis(apiArg: string): ToolApi[] {
    const apisList = this.wksParamsJson.APIS as ToolsApisType [];
    let apiCur: ToolApi[];

    for (const apiParam of apisList) {
      if (apiParam.apiName === apiArg) {
        apiCur = apiParam.apiTools;
      } 
    } 
    return apiCur;
  }
  /*
    switch (apiType) {
      case 'stockManagement': {
        return this.wksParamsJson.APIS.stockManagement;
        break;
      }
      case 'accounting': {
        return this.wksParamsJson.APIS.accounting;
        break;
      }
      case 'notifications': {
        return this.wksParamsJson.APIS.notifications;
        break;
      }
      case 'customersPayments': {
        return this.wksParamsJson.APIS.customersPayments;
        break;
      }
      default : {
        return undefined;
        break;
      }
    }
  }
  */
  public getManufacturers(): Manufacturers[] {
    return  this.wksParamsJson.manufacturers;
  }

  public getSpecificationsProcess():  StepProcess[] {
    return  this.wksParamsJson.specificationsProcess;
  }
  public getSchedulerClasses():  ClassDef[] {
    return  this.wksParamsJson.scheduler.classes;
  }
  /*========================================== anchorages =======================*/

  loadAnchorages() {

    return new Promise<LabelValue[]>((resolve, reject) => {
      this.getWksAnchoragesList()
      .subscribe(
        data => {
          const dataList = data.body;
          const listAnchorages = this.extractListAnchorages(dataList);
          resolve(listAnchorages) ;
        },
        err => {
          console.log(err);
          if ((err.status === 500) || (err.status === 404)) {
            console.log('loadAnchorages : ' + err.message);
          }
          reject(err.status);
        }
      );
    });
  }
  extractListAnchorages(_dataList): LabelValue[]  {
    const anchoragesCacheList: LabelValue[] = [];
    const anchoragesWinteringList: LabelValue[] = [];
    for (const anchorageCur of _dataList) {
      const countryTmp = this._ressourceService.getCountry(anchorageCur.anchorageCountry);
      const anchorageCountryLabel = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
      const labelCur: LabelValue = {
        value: anchorageCur.id,
        label: anchorageCur.anchorageName + ' - ' + anchorageCur.anchorageCity + ' (' + anchorageCountryLabel + ')',
        otherData: anchorageCur.anchoragePosition,
      };
      anchoragesCacheList.push(labelCur);
      if (anchorageCur.anchorageWintering) {
        anchoragesWinteringList.push(labelCur);
      }
    }
    this.setWksAnchorages(anchoragesCacheList);
    this.setWksAnchoragesWintering(anchoragesWinteringList);
    return anchoragesCacheList;

  }
  public setWksAnchorages(wksAnchorages: LabelValue[]) {
    this.wksAnchorages = wksAnchorages;
  }
  public getWksAnchorages(): LabelValue[] {
    return this.wksAnchorages;
  }
  public setWksAnchoragesWintering(wksAnchorages: LabelValue[]) {
    this.wksAnchoragesWintering = wksAnchorages;
  }
  public getWksAnchoragesWintering(): LabelValue[] {
    return this.wksAnchoragesWintering;
  }
 
  /*================================================================== Wks Entity ----*/
  setWksEntityCur(_entityCur: WksEntityModel) {
    this.entityCur = _entityCur;
    // "nautikoNWS"
    if (this.entityCur.stdEntity === 'nautikoNWS') {
      return;
    }
    const entityOptions = JSON.parse(_entityCur.entityOptions);
    if (entityOptions === null) {
      return;
    }
    const quotationActivation = entityOptions.quotationActivation;
    if (quotationActivation !== undefined) {
      this.entityCur.isQuotationActive = quotationActivation;
    }
    const customerOutstanding = entityOptions.customerOutstanding;
    if (customerOutstanding !== undefined) {
      this.entityCur.customerOutstanding = customerOutstanding;
    } else  {
      this.entityCur.customerOutstanding = 0;
    }
  }
  getWksEntityCur(): WksEntityModel {
      return this.entityCur;
  }
  
  getWksEntityHeadquarters(): Observable<HttpResponse<WksEntityModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/wksentity/headquarters/`;
   
    return this.httpClient.get<WksEntityModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksAllEntities(): Observable<HttpResponse<WksEntityModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/wksentity/findAll/`;
   
    return this.httpClient.get<WksEntityModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksEntitySubsidiaries(_entityId: string): Observable<HttpResponse<WksEntityModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/wksentity/subsidiaries/?` + 'entityId=' + _entityId;
   
    return this.httpClient.get<WksEntityModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksEntity(_entity: string): Observable<HttpResponse<WksEntityModel>> {
    
    const urlCur = `${environment.baseUrl}/wks/wksentity/get/?` + 'entity=' + _entity;
   
    return this.httpClient.get<WksEntityModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  // getParams
  getWksEntityInput(_entity: string): Observable<HttpResponse<WksEntityModel>> {
    
    const urlCur = `${environment.baseUrl}/wks/wksentity/getParams/?` + 'entity=' + _entity;
   
    return this.httpClient.get<WksEntityModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  saveWksEntity(_entityCard: WksEntityModel, _action: String): Observable<HttpResponse<WksEntityModel>> {

    const urlCur = `${environment.baseUrl}/wks/wksentity/save/?` + 'action=' + _action;

    const body = JSON.stringify(_entityCard);

    return this.httpClient
      .put<WksEntityModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  testSmtp(stdEntity: string, _smtpCard: SmtpModel): Observable<HttpResponse<String>> {

    const urlCur = `${environment.baseUrl}/wks/wksentity/testSmtp/?` + 'stdEntity=' + stdEntity;

    const body = JSON.stringify(_smtpCard);

    return this.httpClient
      .post<String>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  testApis(stdEntity: string, apiName: string, serviceName: string, toolTestArgs: ToolArgs): Observable<HttpResponse<ToolTest>> {

    const urlCur = `${environment.baseUrl}/wks/wksentity/testApis/?` + 'stdEntity=' + stdEntity + '&apiName=' + apiName + '&serviceName=' + serviceName;

    const body = JSON.stringify(toolTestArgs);

    return this.httpClient
      .post<ToolTest>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  /*================================================================== Wks Business --*/
  // business/byid/
  getWksBusinessId(_entity: string, _id: string): Observable<HttpResponse<WksBusinessModel>> {
    
    const urlCur = `${environment.baseUrl}/wks/business/byid/?` + 'entity=' + _entity + '&id=' + _id;
   
    return this.httpClient.get<WksBusinessModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksBusinessList(_entity: string, _natureCur: string): Observable<HttpResponse<WksBusinessModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/business/?` + 'entity=' + _entity + '&natureCur=' + _natureCur;
   
    return this.httpClient.get<WksBusinessModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  /* getWksContactsList(_entity: string, _natureCur: string, _parent: string): Observable<HttpResponse<WksBusinessModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/business/contacts/?` + 'entity=' + _entity + '&natureCur=' + _natureCur + '&parent=' + _parent;
   
    return this.httpClient.get<WksBusinessModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }*/
  saveWksBusiness(_bsnCard: WksBusinessModel, statutCall: String): Observable<HttpResponse<WksBusinessModel>> {

    const urlCur = `${environment.baseUrl}/wks/business/savebsn/?` + 'action=' + statutCall;
/*
    if (statutCall === 'createBsn') {
      urlCur = `${environment.baseUrl}/wks/business/savebsn/`;
    }
*/
    const body = JSON.stringify(_bsnCard);

    return this.httpClient
      .put<WksBusinessModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }

  /*================================================================== Wks Equipment --*/ 
  searchWksEquipment(_equipCard: WksEquipmentModel): Observable<HttpResponse<WksEquipmentModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/searchequipment/`;

    const body = JSON.stringify(_equipCard);

    return this.httpClient
      .post<WksEquipmentModel[]>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  
  saveWksEquipment(_equipCard: WksEquipmentModel, actionType: String): Observable<HttpResponse<WksEquipmentModel>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/saveequipment/?` + 'action=' + actionType;

    const body = JSON.stringify(_equipCard);

    return this.httpClient
      .put<WksEquipmentModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  getWksEquipmentList(_entity: string, _equipType: string): Observable<HttpResponse<WksEquipmentModel[]>> {
    _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/equipment/?` + 'entity=' + _entity + '&equipType=' + _equipType;
   
    return this.httpClient.get<WksEquipmentModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  /*
  getWksSubEquipmentList(_entity: string, _equipType: string, _mainEquip): Observable<HttpResponse<WksEquipmentModel[]>> {
    _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/equipment/?` + 'entity=' + _entity + '&equipType=' + _equipType+ '&mainEquip=' + _mainEquip;
   
    return this.httpClient.get<WksEquipmentModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  */
  getWksEquipmentByEquipId(_entity: string, _id: string): Observable<HttpResponse<WksEquipmentModel>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/equipid/?` + 'entity=' + _entity + '&id=' + _id;
   
    return this.httpClient.get<WksEquipmentModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  // business/byid/
  getOwner(_entity: string, _id: string): Observable<HttpResponse<WksBusinessModel>> {
    
    const urlCur = `${environment.baseUrl}/wks/business/byid/?` + 'entity=' + _entity + '&id=' + _id;
   
    return this.httpClient.get<WksBusinessModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksEquipmentListOwner(_entity: string, _ownerId: string, _equipType: string): Observable<HttpResponse<WksEquipmentModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/owner/?` + 'entity=' + _entity + '&ownerId=' + _ownerId + '&equipType=' + _equipType;
   
    return this.httpClient.get<WksEquipmentModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksEquipmentListBoat(_entity: string, _equipPartOf: string, _equipType: string): Observable<HttpResponse<WksEquipmentModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/partof/?` + 'entity=' + _entity + '&equipPartOf=' + _equipPartOf + '&equipType=' + _equipType;
   
    return this.httpClient.get<WksEquipmentModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksSubEquipment(_entity: string, _equipmentList: string): Observable<HttpResponse<WksEquipmentModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/subequip/?` + 'entity=' + _entity + '&equiplist=' + _equipmentList;
   
    return this.httpClient.get<WksEquipmentModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
 /*================================================================== Wks histo usage --*/ 

  getHistoUsage(_entity: string, _equipId: string): Observable<HttpResponse<WksEquipmentUsage[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/histoUsage/?` + 'entity=' + _entity + '&equipId=' + _equipId;
    const body = undefined;

    return this.httpClient.post<WksEquipmentUsage[]>(urlCur, body ,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  saveHistoUsage(wksEquipmentUsage: WksEquipmentUsage[], _action: string, ): Observable<HttpResponse<WksEquipmentUsage[]>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/saveHistoUsage/?` + 'action=' + _action;

    const body = JSON.stringify(wksEquipmentUsage);

    return this.httpClient
      .put<WksEquipmentUsage[]>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  } 
  getWksBrandsList(_entity: string): Observable<HttpResponse<BrandWksModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/brands/?` + 'entity=' + _entity;
   
    return this.httpClient.get<BrandWksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksModelsList(_entity: string, _brandName: string, _equipType: string): Observable<HttpResponse<BrandWksModel[]>> {

    _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/equipment/models/?` + 'entity=' + _entity + '&brandname=' + _brandName + '&equiptype=' + _equipType;
   
    return this.httpClient.get<BrandWksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }

  saveWksBrand(_brandCur: BrandWksModel, _action: string ): Observable<HttpResponse<BrandWksModel>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/savebrands/?` + 'action=' + _action;

    const body = JSON.stringify(_brandCur);

    return this.httpClient
      .put<BrandWksModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  /*======================================================================== Docs */
  /*
  getLinkSharing(_docCur: WksDocsModel, _dateCut: string, dataTmp: WksTmpData) {
    const linkValues = _dateCut + ';' + _docCur.docRef;
    // const keyHash = btoa(linkValues);
    const keyHash = CommonMethods.encryptAES(linkValues, dataTmp.dataCalc);
    // encodeURIComponent(keyHash.toString());
    const linkLocal = dataTmp.dataRef + 'AAA' + keyHash;
    const completeURL = `${environment.baseUrl}/wks/sharedLink/?` + 'doclink=' + linkLocal;
    const completeUrlEncoded = encodeURIComponent(completeURL);
    // console.log(completeUrlEncoded);
    return `${environment.baseUrl}/wks/sharedLink/?` + 'doclink=' + encodeURIComponent(linkLocal);
    
  }
  */
  getLinkPhotos(photoData: PhotoData, entity: string): Observable<HttpResponse<any>> {

    const urlCur = `${environment.baseUrl}/wks/getWaitingLinkPhotos/?` + 'application=wksPhotos&linkApp=photos&entity=' + entity;

    const body = JSON.stringify(photoData);

    return this.httpClient
      .post<any>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getLinkSharing(_docCur: WksDocsModel): Observable<HttpResponse<any>> {

    const urlCur = `${environment.baseUrl}/wks/getWaitingLink/?` + 'application=wksDocs&linkApp=document';

    const body = JSON.stringify(_docCur);

    return this.httpClient
      .post<any>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  searchWksDoc(_equipCard: WksDocsModel): Observable<HttpResponse<WksDocsModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/searchdoc/`;

    const body = JSON.stringify(_equipCard);

    return this.httpClient
      .post<WksDocsModel[]>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  getWksDocListEquip(_entity: string, _equipType: string, _docBrand: string, _docModel: string): Observable<HttpResponse<WksDocsModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/documents/?` + 'entity=' + _entity + '&equiptype=' + _equipType + 
                                                              '&docbrand=' + _docBrand + '&docmodel=' + _docModel;
    return this.httpClient.get<WksDocsModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksDocList(_entity: string): Observable<HttpResponse<WksDocsModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/equipment/documents/?` + 'entity=' + _entity + '&equiptype=null&docbrand=null&docmodel=null';
   // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<WksDocsModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  getWksDocListOwner(_entity: string, _ownerId: string): Observable<HttpResponse<WksDocsModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/business/documents/?` + 'entity=' + _entity + '&ownerId=' + _ownerId;
   // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<WksDocsModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  saveWksDoc(_docCard: WksDocsModel, actionType: String): Observable<HttpResponse<WksDocsModel>> {

    const urlCur = `${environment.baseUrl}/wks/equipment/savedocs/?` + 'action=' + actionType;

    const body = JSON.stringify(_docCard);

    return this.httpClient
      .put<WksDocsModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  
  downloadDoc(_path: string, _mimeApp: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;
 
    const localHttpHeaders = this.httpHeaders;
    localHttpHeaders.set('Accept', _mimeApp);
    
    return this.httpClient.get<any> ( urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
  public saveToFileSystem(_data: any, _filename: string, _mimeApp: string) {

    const blob = new Blob([_data], { type: _mimeApp });
    saveAs(blob, _filename);
  }
  getPdfDoc(_path: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    const urlCur = `${environment.baseUrl}/wks/equipment/document/download/?` + 'path=' + _path;

    const localHttpHeaders = this.httpHeaders;
    localHttpHeaders.set('Accept', 'application/pdf');
    
    return this.httpClient.post<any> ( urlCur, undefined,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
  /*========================================================================= wksContacts --*/
  getContactsList(_entity: string, _cntAppli: string, _cntAppliLnk: string): Observable<HttpResponse<WksContactsModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/wkscontacts/get/?` + 'entity=' + _entity + '&cntappli=' + _cntAppli + '&cntapplilnk=' + _cntAppliLnk;
   // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<WksContactsModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
  /*========================================================================= wksAnchorages --*/
  getWksAnchoragesList(): Observable<HttpResponse<WksAnchoragesModel[]>> {
    
    const urlCur = `${environment.baseUrl}/wks/anchorages/getAll`;
   // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<WksAnchoragesModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }

  saveWksAnchorage(_anchorageCard: WksAnchoragesModel, actionType: String): Observable<HttpResponse<WksAnchoragesModel>> {

    const urlCur = `${environment.baseUrl}/wks/anchorages/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_anchorageCard);

    return this.httpClient
      .put<WksAnchoragesModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  /*========================================================================= paramCustoms --*/
  saveParamCustoms(_paramCustom: ParamCustoms, actionType: String): Observable<HttpResponse<ParamCustoms>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/save/?` + 'action=' + actionType;
  
    const body = JSON.stringify(_paramCustom);
  
    return this.httpClient
      .put<ParamCustoms>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  getParamCustomsEcoZoneList(_ecoZoneCode: string): Observable<HttpResponse<ParamCustoms[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/getEcoZone/?` + 'ecoZoneCode=' + _ecoZoneCode;

    return this.httpClient.get<ParamCustoms[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        }));
  }
  getParamCustomsHsCode(_ecoZoneCode: string, _hsCode: string): Observable<HttpResponse<ParamCustoms>> {
  
    const urlCur = `${environment.baseUrl}/wks/jobs/paramcustoms/getHsCode/?` + 'ecoZoneCode=' + _ecoZoneCode + 
              '&hsCode=' +  _hsCode;

    return this.httpClient.get<ParamCustoms>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        }));
  }
  /*========================================================================= wksWorksRates --*/
  saveWksWorkRates(_workrateCard: ParamWorksRatesModel, actionType: String): Observable<HttpResponse<ParamWorksRatesModel>> {


    const urlCur = `${environment.baseUrl}/wks/jobs/paramworksrates/save/?` + 'action=' + actionType;
  
    const body = JSON.stringify(_workrateCard);
  
    return this.httpClient
      .put<ParamWorksRatesModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  
  getWksWorkRatesList(_entity: string, _userlang: string): Observable<HttpResponse<ParamWorksRatesModel[]>> {
  
    const urlCur = `${environment.baseUrl}/wks/jobs/paramworksrates/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;
   // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<ParamWorksRatesModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        }));
  }
/*========================================================================= paramPartsSupplies --*/
  updatesRatesPartsSupplies(__updateRatesValues: UpdateRatesModel, _actionType: string): Observable<HttpResponse<UpdateRatesModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/updateRates/?` + 'action=' + _actionType;

    const body = JSON.stringify(__updateRatesValues);

    return this.httpClient
      .put<UpdateRatesModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  
  saveParamPartSupplie(_partSupplieCard: ParamPartsSuppliesModel, actionType: String): Observable<HttpResponse<ParamPartsSuppliesModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_partSupplieCard);

    return this.httpClient
      .put<ParamPartsSuppliesModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), );
  }
  getParamPartSupplieList(_entity: string, _brandName: string, _paramType: string, _userlang: string, _brandRef: string): Observable<HttpResponse<ParamPartsSuppliesModel[]>> {
    
    // _brandRef = CommonMethods.cleanURLString(_brandRef);
    if (_brandRef.includes('|')) {
      // _brandRef.replace('|', ',,');
      // (/\\/g, '');
      // _brandRef.replace(/\\|/g, ',,');
      const brandRefOr = _brandRef.split('|');
      let iLoop = 0;
      for (const brandOr of brandRefOr) {
        if (iLoop === 0) {
          _brandRef = brandOr;
        } else  {
          _brandRef += ',,' + brandOr ;
        }
        iLoop++;
      }
    }
    if (_brandRef.includes('+')) {
      // _brandRef.replace('|', ',,');
      // (/\\/g, '');
      // _brandRef.replace(/\\|/g, ',,');
      const brandRefOr = _brandRef.split('+');
      let iLoop = 0;
      for (const brandOr of brandRefOr) {
        if (iLoop === 0) {
          _brandRef = brandOr;
        } else  {
          _brandRef += ';' + brandOr ;
        }
        iLoop++;
      }
    }
    const urlCur = `${environment.baseUrl}/wks/jobs/parampartsupplies/getPartSupplies/?` + 'entity=' + _entity 
                                                                                        + '&brandName=' + _brandName
                                                                                        + '&paramType=' + _paramType 
                                                                                        + '&brandRef=' + _brandRef
                                                                                        + '&userlang=' + _userlang;
  // "equiptype", "docbrand", "docmodel"
    return this.httpClient.get<ParamPartsSuppliesModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        }));
  }
  verifyStockParts(_stdEntity: string, _workId: string): Observable<HttpResponse<any>> {
    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/verifyStockParts/?` + 'entity=' + _stdEntity + '&workId=' + _workId ;

    const body = JSON.stringify('');

    return this.httpClient
      .post<any>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
/*========================================================================= paramactionsref --*/
  saveParamActionsRef(_paramActionsRefCard: ParamActionsrefModel, actionType: String): Observable<HttpResponse<ParamActionsrefModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_paramActionsRefCard);

    return this.httpClient
      .put<ParamActionsrefModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  getParamActionsRefList(_entity: string, _userlang: string): Observable<HttpResponse<ParamActionsrefModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;

    return this.httpClient.get<ParamActionsrefModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getParamActionsRefByTypeList(_entity: string, _equipType: string, _userlang: string): Observable<HttpResponse<ParamActionsrefModel[]>> {
    _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/jobs/paramactionsref/getByType/?` + 'entity=' + _entity + '&equipType=' + _equipType + '&userlang=' + _userlang;

    return this.httpClient.get<ParamActionsrefModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  /*========================================================================= ParamTasksref --*/
  saveParamTasksref(_paramTasksrefCard: WksTasksModel, actionType: String): Observable<HttpResponse<WksTasksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_paramTasksrefCard);

    return this.httpClient
      .put<WksTasksModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  getParamTasksrefByTypeList(_entity: string, _equipType: string, _userlang: string, _equipBrand: string, _equipModel: string ): Observable<HttpResponse<WksTasksModel[]>> {
    _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getByType/?` + 'entity=' + _entity + 
                                    '&equipType=' + _equipType + '&userlang=' + _userlang + 
                                    '&equipBrand=' + _equipBrand + '&equipModel=' + _equipModel;

    return this.httpClient.get<WksTasksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getTaskRefList(_entity: string, _userlang: string, _taskList: string): Observable<HttpResponse<WksTasksModel[]>> {
   //  _equipType = CommonMethods.cleanURLString(_equipType);
    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getTaskRefList/?` + 'entity=' + _entity + 
                                    '&userlang=' + _userlang + 
                                    '&taskList=' + _taskList;

    return this.httpClient.get<WksTasksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getParamTasksrefList(_entity: string, _userlang: string): Observable<HttpResponse<WksTasksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/get/?` + 'entity=' + _entity + '&userlang=' + _userlang;

    return this.httpClient.get<WksTasksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getParamTasksrefChildren(_entity: string, _userlang: string, _taskrefparent: string): Observable<HttpResponse<WksTasksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getchildren/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&taskrefparent=' + _taskrefparent;

    return this.httpClient.get<WksTasksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getParamTasksref(_entity: string, _userlang: string, _taskrefId: string): Observable<HttpResponse<WksTasksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wkstasks/getTaskRef/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&taskrefid=' + _taskrefId;

    return this.httpClient.get<WksTasksModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  /*==================================================================== wks works --*/
  getWorksHistory(_entity: string, _userlang: string, _equipRequest: WksEquipmentsRequest ): Observable<HttpResponse<WksWorksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getHistory/?` + 'userlang=' + _userlang;

    const body = JSON.stringify(_equipRequest);

    return this.httpClient
      .post<WksWorksModel[]>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );

  }
  getWorksList(_entity: string, _userlang: string): Observable<HttpResponse<WksWorksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getlist/?` + 'entity=' + _entity + '&userlang=' + _userlang ;

    return this.httpClient.get<WksWorksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getWorksByStatut(_entity: string, _userlang: string, _statut: string): Observable<HttpResponse<WksWorksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/bystatut/?` + 'entity=' + _entity + '&statut=' + _statut + '&userlang=' + _userlang  ;

    return this.httpClient.get<WksWorksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }

  getWorksByCriticality(_entity: string, _userlang: string, _criticality: string): Observable<HttpResponse<WksWorksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/bystatut/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&criticality=' + _criticality  ;

    return this.httpClient.get<WksWorksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  getWorksById(_entity: string, _userlang: string, _workId: string): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/byid/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _workId  ;

    return this.httpClient.get<WksWorksModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  
  getWorkId(): Observable<HttpResponse<string>> {
    
  const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getworkid/`;

  return this.httpClient.get<string>(urlCur,
    {
      headers: this.httpHeaders,
      observe: 'response'
    })
    
    .pipe(
      // catchError(this.handleErrorWksBusinessList), );
      catchError(err => {
        console.log(err.status);
        return observableThrowError(err);
      } ));
  }
  getOthersWorks(_equiId: string, _workId: string, _userlang: string, _equipPartOf: string): Observable<HttpResponse<WksWorksModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/othersworks/?` + 'equipid=' + _equiId 
                                                                              + '&id=' + _workId
                                                                              + '&userlang=' + _userlang
                                                                              + '&equipPartOf=' + _equipPartOf;

    return this.httpClient.get<WksWorksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  generateQuotation(_entity: string, _userlang: string, _id: string): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/generateQuotation/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _id  ;

    return this.httpClient.get<WksWorksModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  displayQuotation(_entity: string, _userlang: string, _id: string): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getquotation/?` + 'entity=' + _entity + '&userlang=' + _userlang + '&id=' + _id  ;

    return this.httpClient.get<WksWorksModel>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
      }));
  }
  updateQuotation(_userlang: string, _docId: string, _wksWorks: WksWorksModel, _action: string): Observable<HttpResponse<WksWorksModel>> {
    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/updatequotation/?` + 'userlang=' + _userlang 
                                                        + '&docId=' + _docId +  '&action=' + _action;

    const body = JSON.stringify(_wksWorks);

    return this.httpClient
      .put<WksWorksModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable), 
      );
  }
  getTasksAndDocsWork(_wksWork: WksWorksModel, entity: String, userlang: string ): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/getTasksAndDocsWork/?` + 'entity=' + entity + '&userlang=' + userlang ;
    // const body = JSON.stringify(_wksWork);
    const body = JSON.parse(JSON.stringify(_wksWork));

    return this.httpClient
      .post<WksWorksModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  saveWksWorks(_wksWorksModel: WksWorksModel, actionType: String): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_wksWorksModel);

    return this.httpClient
      .put<WksWorksModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  lockWork(wksWorksCur: WksWorksModel, entity: string, userlang: string): Observable<HttpResponse<WksWorksModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/lockWork/?` + 'entity=' + entity + '&userlang=' + userlang ;
 
    return this.httpClient.post<WksWorksModel>(urlCur, wksWorksCur, {
        headers: this.httpHeaders,
        observe: 'response',
      });
}
  removeWksWorksDoc(_wksWorksDocs: WksWorksDocs): Observable<HttpResponse<WksWorksDocs>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/remove/`;

    const body = JSON.stringify(_wksWorksDocs);

    return this.httpClient
      .put<WksWorksDocs>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  downloadWorkImg(_path: string, _mimeApp: string, _imageType: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/download/?` + 'path=' + _path + '&type=' + _imageType;

    const localHttpHeaders = this.httpHeaders;
    localHttpHeaders.set('Accept', _mimeApp);
    
    return this.httpClient.get<any> ( urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );
  }
  downloadWorkPdf(_path: string): Observable<HttpResponse<any>> {
    _path = CommonMethods.cleanURLString(_path);
    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/document/download/?` + 'path=' + _path + '&type=pdf';

    const localHttpHeaders = this.httpHeaders;
    localHttpHeaders.set('Accept', 'application/pdf');
    
    return this.httpClient.get<any> ( urlCur,
      {
        headers: localHttpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      }).pipe(
        map((result: any) => {
            return result;
        })
    );

  }
  getWksAppointments(_entity: string, _statut: string, _timeZone: string): Observable<HttpResponse<AppointmentModel[]>> {
      
    let urlCur = `${environment.baseUrl}/wks/appointments/getAllAppointments/?` + 'entity=' + _entity + '&timezone=' + _timeZone;
    if (_statut !== undefined && _statut !== null  ) {
      urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsByStatut/?` + 'entity=' + _entity + '&statut=' + _statut + '&timezone=' + _timeZone;
    }
    return this.httpClient.post<AppointmentModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAppointmentsWaitingDate(_entity: string, _statut: string, _timeZone: string): Observable<HttpResponse<AppointmentModel[]>> {
      
    const urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsWaitingDate/?` + 'entity=' + _entity + '&statut=' + _statut + '&timezone=' + _timeZone;

    return this.httpClient.post<AppointmentModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAppointmentUUID(_entity: string, _uuid: string, _timeZone: string): Observable<HttpResponse<AppointmentModel>> {
      
    const urlCur = `${environment.baseUrl}/wks/appointments/getAppointmentsByUUID/?` + 'entity=' + _entity + '&uuid=' + _uuid + '&timezone=' + _timeZone;

    return this.httpClient.post<AppointmentModel>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getWksEntityParams(_entity: string): Observable<HttpResponse<any>> {

    const urlService = `${environment.baseUrl}/wks/appointments/getEntityParams/?` + 'entity=' + _entity  ;
    const localHttpHeaders: HttpHeaders  = this.httpHeaders;
    return this.httpClient
    .post<any>(urlService, undefined, {
        headers: localHttpHeaders,
        observe: 'response'
    });

  }
  saveWksAppointment(_appointmentCur: AppointmentModel, actionType: String, actorEmail: string): Observable<HttpResponse<AppointmentModel>> {

    const urlCur = `${environment.baseUrl}/wks/appointments/wksAppointment/save/?` + 'action=' + actionType  + '&actorEmail=' + actorEmail;

    const body = JSON.stringify(_appointmentCur);

    return this.httpClient
      .put<AppointmentModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  saveWksAppointmentTracking(_appointmentTracking: AppointmentTracking, actionType: String): Observable<HttpResponse<AppointmentTracking>> {

    const urlCur = `${environment.baseUrl}/wks/appointments/wksTrackingAppointment/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_appointmentTracking);

    return this.httpClient
      .put<AppointmentTracking>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  saveWksAccounting(_accountingCur: AccountingModel, actionType: String): Observable<HttpResponse<AccountingModel>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/save/?` + 'action=' + actionType;

    const body = JSON.stringify(_accountingCur);

    return this.httpClient
      .put<AccountingModel>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      }).pipe(
        catchError(this.handleErrorObservable)
      );
  }
  getAccountingTransferlist(_entity: string): Observable<HttpResponse<AccountingModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getTransferlist/?` + 'entity=' + _entity ;

    return this.httpClient.post<AccountingModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAccountingEntitylist(_entity: string, customerId: string): Observable<HttpResponse<AccountingModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getEntitylist/?` + 'entity=' + _entity + '&customerId=' + customerId ;

    return this.httpClient.post<AccountingModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAccountingWorklist(_entity: string, workId: string): Observable<HttpResponse<AccountingModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getWorklist/?` + 'entity=' + _entity + '&workId=' + workId ;

    return this.httpClient.post<AccountingModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAccountingWorkBalance(_entity: string, workId: string): Observable<HttpResponse<AccountingBalance>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksworks/balance/?` + 'entity=' + _entity + '&workId=' + workId ;

    return this.httpClient.post<AccountingBalance>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAccountingCustomerBalance(_entity: string, customerId: string): Observable<HttpResponse<AccountingBalance>> {

    const urlCur = `${environment.baseUrl}/wks/business/balance/?` + 'entity=' + _entity + '&customerId=' + customerId ;

    return this.httpClient.post<AccountingBalance>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
  getAccountingCustomerlist(_entity: string): Observable<HttpResponse<AccountingModel[]>> {

    const urlCur = `${environment.baseUrl}/wks/jobs/wksAccounting/getCustomerlist/?` + 'entity=' + _entity ;

    return this.httpClient.post<AccountingModel[]>(urlCur, undefined,
      {
        headers: this.httpHeaders,
        observe: 'response'
      })
      .pipe(
        // catchError(this.handleErrorWksBusinessList), );
        catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
          } 
        )
      );
  }
/*========================================================================= Menu --*/
  getWksMenu(): Observable<HttpResponse<MenuWksModel[]>> {
    const urlCur = `${environment.baseUrl}/wks/menu/`;
    // this.retrieveUser();

    return this.httpClient.get<MenuWksModel[]>(urlCur,
      {
        headers: this.httpHeaders,
        observe: 'response'
      });
  }
  public setWksMenuButton(_menuItems: any) {
    this.wksMenuItems = _menuItems;
    this.wksMenuButtonEvents.next(_menuItems);
  }
  public setWksMenuItems(_menuItems: any) {
    this.wksMenuItems = _menuItems;
    this.wksMenuItemsEvents.next(_menuItems);
  }
  public getWksMenuItems(): any {
    return this.wksMenuItems;
  }
  private handleErrorObservable (error: Response | any) {
    // console.error(error.message || error);
    return observableThrowError(error.message || error);
  }

  downloadImgFile(_path: string, _imgFile: string) {
     
    const fileExtension = _imgFile.split('.').pop();
    const mimeApp = this.getContentMime(fileExtension);
      return new Promise((resolve, reject) => {
        this.downloadWorkImg(_path, mimeApp, fileExtension)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(
          (retMes: any) => {
            const response = retMes.body;
            const blob = new Blob([response], { type: mimeApp, });
            resolve(blob);
          }, err => {
            reject(err);
          }
        );
      });
  }  
}
